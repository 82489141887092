import React from "react";
import {
  SEO,
  PageLayout,
  HeroElement,
  BlogPostNavigation,
} from "@bluefin/components";
import { Grid } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getPageHeroImage } from "../utils/utils";

export default class BlogPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      allFishermanBusinessBlogPost,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getPageHeroImage({
            options: fishermanBusinessWebsitePage.components,
          })}
        />
        <PageLayout
          hero={
            <HeroElement
              header={"Blog"}
              tagline={""}
              images={getPageHeroImage({
                options: fishermanBusinessWebsitePage.components,
              })}
              ctaInverted={true}
              ctaColor={"white"}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={true}
              fullWidth={true}
              parallax={false}
              containerAs={true}
              height={500}
              gutter={false}
            />
          }
        >
          <Grid stackable={true} className={"blog-page-container"}>
            <Grid.Column width={16}>
              <BlogPostNavigation
                title={""}
                description={""}
                featuredPostId={null}
                alwaysShowSearch={true}
                columns={4}
                posts={allFishermanBusinessBlogPost.nodes}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(title: { eq: "Blog" }) {
      title
      components {
        fastId
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              fluid(maxWidth: 720) {
                aspectRatio
                base64
                src
                srcSet
              }
            }
          }
        }
      }
    }
    allFishermanBusinessBlogPost {
      nodes {
        _id
        title
        slug
        author {
          firstName
          lastName
          avatar
          email
        }
        summary
        content
        heroImage
        gatsbyHeroImage {
          childImageSharp {
            fluid(maxWidth: 500) {
              aspectRatio
              base64
              src
              srcSet
            }
          }
        }
        publishedAt
      }
    }
  }
`;
